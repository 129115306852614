<template>
    <b-container v-if="customer">
        <h3 class="mt-3">{{ customer.id ? '修改客戶' : '建立客戶' }}</h3>
        <b-form class="mt-3" @submit.stop.prevent="onSubmit">
            <h5>內容</h5>
            <b-form-group label="名稱">
                <b-form-input v-model="customer.name" required></b-form-input>
            </b-form-group>
            <b-form-group label="電話號碼">
                <b-form-input v-model="customer.phone" required></b-form-input>
            </b-form-group>
            <b-form-group label="性別">
                <b-form-select v-model="customer.gender" :options="genderOptions" required></b-form-select>
            </b-form-group>
            <b-form-group label="生日月份">
                <b-form-select v-model="customer.birthMonth" :options="birthMonthOptions" required></b-form-select>
            </b-form-group>

            <h5>積分</h5>
            <b-row>
                <b-col>
                    <b-form-group label="累計積分">
                        <b-input-group append="分">
                            <b-form-input v-model="customer.accumulatedPoints" type="number" step="1" required></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group label="積分結餘">
                        <b-input-group append="分">
                            <b-form-input v-model="customer.pointBalance" type="number" step="1" required></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <b-form-group>
                        <b-button block @click="$router.go(-1)">返回</b-button>
                    </b-form-group>
                </b-col>
                <b-col>
                    <b-form-group>
                        <b-button type="submit" variant="primary" block>儲存</b-button>
                    </b-form-group>
                </b-col>
            </b-row>
        </b-form>
    </b-container>
</template>

<script>
import { Customer } from '@/models'

export default {
    data() {
        return {
            action: this.$route.params.action,
            customer: null,
            genderOptions: [
                { value: 'M', text: '男' },
                { value: 'F', text: '女' },
            ],
            birthMonthOptions: [
                { value: '1', text: '1月' },
                { value: '2', text: '2月' },
                { value: '3', text: '3月' },
                { value: '4', text: '4月' },
                { value: '5', text: '5月' },
                { value: '6', text: '6月' },
                { value: '7', text: '7月' },
                { value: '8', text: '8月' },
                { value: '9', text: '9月' },
                { value: '10', text: '10月' },
                { value: '11', text: '11月' },
                { value: '12', text: '12月' },
            ],
        }
    },

    async created() {
        this.customer = this.action == 'create' ? Customer() : await this.$store.dispatch('getDocument', { col: 'customers', id: this.$route.params.id })
        if (!this.customer) return this.$router.push('/customers')
    },

    methods: {
        async onSubmit() {
            this.customer.merchantId = localStorage.merchantId

            let res = null
            if (this.action == 'create') res = await this.$store.dispatch('createCustomer', { customer: this.customer })
            else res = await this.$store.dispatch('updateDocument', { col: 'customers', doc: this.customer })
            this.$root.$bvToast.toast(`${this.action == 'create' ? '新增' : '更新'}${res ? '成功' :'失敗'}`, { title: res ? '成功' :'失敗', variant: res ? 'success' : 'danger' })
            return this.$router.push('/customers')
        }
    }
}
</script>